import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import './main.css'
import * as Sentry from '@sentry/vue'

import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { fetchUserClaims } from '@/firebase/auth/user'
import { auth } from '@/firebase'

import { createI18n } from 'vue-i18n'
import enLocale from './lang/en.json'
import deLocale from './lang/de.json'

let i18n

// const createVueApp = (locale = 'en') => {
// 	// Global error handler
// 	// app.config.errorHandler = (err, instance, info) => {
// 	// 	console.error(err)
// 	// }

// 	return app
// }

// onAuthStateChanged(auth, async (user) => {
// 	let locale = 'en' // default locale

// 	if (user) {
// 		try {
// 			const userClaims = await fetchUserClaims()
// 			if (userClaims) {
// 				locale = userClaims.platformLang
// 			}
// 		} catch (error) {
// 			console.error('Error fetching user claims:', error)
// 		}
// 	}

// 	if (app) {
// 		app.unmount()
// 	}
// 	app = createVueApp(locale)
// })

i18n = createI18n({
	legacy: false,
	locale: 'en', // default locale
	messages: {
		en: enLocale,
		de: deLocale
	}
})

const app = createApp(App)
const pinia = createPinia()

Sentry.init({
	app,
	environment: import.meta.env.VITE_ENV,
	dsn: 'https://47258f555d08d58732d85a448a852f0e@o4507368001503232.ingest.de.sentry.io/4507368003928144',
	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ['localhost', /^https:\/\/saleswave\.ai\//],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})
app.use(pinia).use(router).use(i18n)
app.mount('#app')
