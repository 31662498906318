<template>
	<div>
		<RouterView></RouterView>
	</div>
</template>

<script setup></script>

<style>
body {
	background-color: rgb(250, 252, 253);
}
</style>
