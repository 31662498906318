import { createRouter, createWebHistory } from 'vue-router'

import { getAuth, onAuthStateChanged } from 'firebase/auth'

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('../views/MainView.vue')
	},
	{
		path: '/signup',
		name: 'signup',
		component: () => import('../views/SignUpView.vue')
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/LoginView.vue'),
		meta: {
			requiresUnAuth: true
		}
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'not-found',
		redirect: '/'
	},
	{
		path: '/app',
		component: () => import('../views/app/AppView.vue'),
		meta: {
			requiresAuth: true
		},
		children: [
			{
				path: '',
				redirect: 'decks',
				meta: {
					requiresAuth: true
				}
			},
			{
				path: 'decks',
				name: 'Home',
				component: () => import('../views/app/DecksView.vue'),
				meta: {
					requiresAuth: true
				}
			},
			{
				path: 'guide/:deckId',
				component: () => import('../views/app/CardsView/Guide1View.vue'),
				name: 'Guide1View',
				props: true,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: 'productguide/:deckId',
				component: () => import('../views/app/CardsView/ProductGuideView.vue'),
				name: 'ProductGuideView',
				props: true,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: 'competitorguide/:deckId',
				component: () => import('../views/app/CardsView/CompetitorGuideView.vue'),
				name: 'CompetitorGuideView',
				props: true,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: 'settings',
				name: 'Settings',
				component: () => import('../views/app/CompanySettingsView.vue'),
				meta: {
					requiresAuth: true
				}
			},
			{
				path: 'usersettings',
				name: 'UserSettings',
				component: () => import('../views/app/UserSettingsView.vue'),
				meta: {
					requiresAuth: true
				}
			},

			{
				path: 'products',
				name: 'Products',
				component: () => import('../views/app/ProductView.vue'),
				meta: {
					requiresAuth: true
				}
			},
			{
				path: 'files',
				name: 'Files',
				component: () => import('../views/app/FilesView.vue'),
				meta: {
					requiresAuth: true
				}
			},
			{
				path: 'personas',
				name: 'Personas',
				component: () => import('../views/app/PersonasView.vue'),
				meta: {
					requiresAuth: true
				}
			},
			{
				path: 'meeting-genius',
				name: 'MeetingGenius',
				component: () => import('../views/app/MeetingGeniusView.vue'),
				meta: {
					requiresAuth: true
				}
			},
			{
				path: 'dashboard',
				name: 'Dashboard',
				component: () => import('../views/app/DashboardView.vue'),
				meta: {
					requiresAuth: true
				}
			}
		]
	}
]

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes
})

router.beforeEach(async (to, from, next) => {
	const auth = getAuth()

	// Check if the route requires authentication
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		// for pages with requiresAuth push to /login if the user is not authenticated
		try {
			// Use a Promise to wait for the onAuthStateChanged observer to resolve
			await new Promise((resolve) => {
				const unsubscribe = onAuthStateChanged(auth, (user) => {
					unsubscribe()
					if (user) {
						next()
					} else {
						next('/login')
						resolve()
						console.error('User tried to access page without permission!')
					}
				})
			})
		} catch (error) {
			console.error('Error checking authentication state:', error)
			next('/login')
		}
	} else if (to.matched.some((record) => record.meta.requiresUnAuth)) {
		// for pages with requiresUnAuth push to /decks if the user is authenticated
		try {
			// Use a Promise to wait for the onAuthStateChanged observer to resolve
			await new Promise((resolve) => {
				const unsubscribe = onAuthStateChanged(auth, (user) => {
					unsubscribe()
					if (user) {
						next('/app/decks')
						resolve()
					} else {
						next()
						resolve()
					}
				})
			})
		} catch (error) {
			console.error('Error checking authentication state:', error)
			next('/login')
		}
	} else {
		next()
	}
})

export default router
